// ** React Imports
import { memo } from "react";

// ** Third Party Components
import { useTranslation } from "react-i18next";

// ** Custom Components
import Heading from "components/typography/Heading";
import Paragraph from "components/typography/Paragraph";
import Block from "components/layout/Block";
import Text from "components/typography/Text";

// #####################################################

const WelcomeTestCta = () => {
	const { t } = useTranslation(["landing"]);

	// #####################################################

	return (
		<Block flex column fullWidth>
			<Block pt={4}>
				<Heading color={"whiteAlways"} bold type="h1" size="1.75rem">
					{t("landing:header")}
				</Heading>
			</Block>
			<Block pt={2} mb={4}>
				<Text color={"whiteAlways"}>
					<Paragraph size="1.25rem" bold>
						{t("landing:subheaderCta")}
					</Paragraph>
				</Text>
			</Block>
		</Block>
	);
};

// #####################################################

export default memo(WelcomeTestCta);
